import { Gear, UserSettings } from '@iheartradio/web.accomplice/icons';
import { Link } from '@iheartradio/web.accomplice/link';
import { NavigationItem } from '@iheartradio/web.accomplice/navigation';
import type { User } from '@iheartradio/web.config';
import { $path } from 'remix-routes';

export function LoginSettingsNavItem({
  user,
  onClick,
}: {
  user: User | null;
  onClick: () => void;
}) {
  return !user || user?.isAnonymous ?
      <NavigationItem asChild>
        <Link
          data-test="login"
          href={$path('/login/:action?', { action: 'auth' })}
          onPress={onClick}
          underline="none"
        >
          <UserSettings />
          Log In
        </Link>
      </NavigationItem>
    : <NavigationItem asChild>
        <Link
          data-test="settings"
          href={$path('/settings')}
          target="_blank"
          underline="none"
        >
          <Gear />
          Settings
        </Link>
      </NavigationItem>;
}
