import { Button } from '@iheartradio/web.accomplice/button';
import { EllipsisHorizontal } from '@iheartradio/web.accomplice/icons';
import {
  MenuContent,
  MenuItem,
  MenuTrigger,
} from '@iheartradio/web.accomplice/menu';
import {
  MARK_AS_PLAYED_ACTION,
  MARK_AS_UNPLAYED_ACTION,
  Playback,
} from '@iheartradio/web.playback';
import { isNull } from '@iheartradio/web.utilities';
import { useFetcher } from '@remix-run/react';
import { memo, useState } from 'react';
import { $path } from 'remix-routes';

import { trackClick } from '~app/analytics/track-click';
import { useFollowUnfollow } from '~app/hooks/use-follow-unfollow/use-follow-unfollow';
import { useGetPageName } from '~app/hooks/use-get-page-name';
import {
  buildAlbumUrl,
  buildArtistUrl,
  buildLiveUrl,
  buildPlaylistUrl,
  buildPodcastEpisodeUrl,
  buildPodcastUrl,
} from '~app/utilities/urls';

import { playback } from '../playback';

const canGoToArtist = new Set([
  Playback.StationType.Album,
  Playback.StationType.Live,
  Playback.StationType.Scan,
  Playback.StationType.Artist,
  Playback.StationType.Favorites,
  Playback.StationType.Podcast,
  Playback.StationType.Playlist,
  Playback.StationType.PlaylistRadio,
  Playback.StationType.TopSongs,
]);

const canGoToAlbum = new Set([
  Playback.StationType.Album,
  Playback.StationType.Artist,
  Playback.StationType.Favorites,
  Playback.StationType.Playlist,
  Playback.StationType.PlaylistRadio,
  Playback.StationType.TopSongs,
]);

const canGoToPlaylist = new Set([
  Playback.StationType.Playlist,
  Playback.StationType.PlaylistRadio,
]);

const FollowPodcast = memo(function FollowPodcast({
  podcastId,
  podcastName,
  setIsMenuOpen,
}: {
  podcastId: number;
  podcastName: string;
  setIsMenuOpen?: (open: boolean) => void;
}) {
  const { isFollowing, follow, unfollow } = useFollowUnfollow({
    contentId: String(podcastId),
    contentType: Playback.StationType.Podcast,
    contentName: podcastName,
    section: 'player',
    onSuccess: () => {
      setIsMenuOpen?.(false);
    },
  });

  return isFollowing ?
      <MenuItem
        onAction={() => {
          unfollow();
        }}
      >
        Unfollow podcast
      </MenuItem>
    : <MenuItem
        onAction={() => {
          follow();
        }}
      >
        Follow podcast
      </MenuItem>;
});

const FollowPlaylist = memo(function FollowPlaylist({
  playlistId,
  playlistName,
  userId,
  setIsMenuOpen,
}: {
  playlistId: string;
  playlistName: string;
  userId: string;
  setIsMenuOpen?: (open: boolean) => void;
}) {
  const { isFollowing, follow, unfollow } = useFollowUnfollow({
    authorId: userId,
    contentId: playlistId,
    contentType: Playback.StationType.Playlist,
    contentName: playlistName,
    section: 'player',
    onSuccess: () => {
      setIsMenuOpen?.(false);
    },
  });

  return isFollowing ?
      <MenuItem
        onAction={() => {
          unfollow();
        }}
      >
        Unfollow playlist
      </MenuItem>
    : <MenuItem
        onAction={() => {
          follow();
        }}
      >
        Follow playlist
      </MenuItem>;
});

const FollowArtist = memo(function FollowArtist({
  artistId,
  artistName,
  setIsMenuOpen,
}: {
  artistId: string;
  artistName: string;
  setIsMenuOpen?: (open: boolean) => void;
}) {
  const { isFollowing, follow, unfollow } = useFollowUnfollow({
    contentId: artistId,
    contentType: Playback.StationType.Artist,
    contentName: artistName,
    section: 'player',
    onSuccess: () => {
      setIsMenuOpen?.(false);
    },
  });

  return isFollowing ?
      <MenuItem
        onAction={() => {
          unfollow();
        }}
      >
        Unfollow artist
      </MenuItem>
    : <MenuItem
        onAction={() => {
          follow();
        }}
      >
        Follow artist
      </MenuItem>;
});

const FollowLive = memo(function FollowLive({
  stationId,
  stationName,
  setIsMenuOpen,
}: {
  stationId: string;
  stationName: string;
  setIsMenuOpen?: (open: boolean) => void;
}) {
  const { isFollowing, follow, unfollow } = useFollowUnfollow({
    contentId: stationId,
    contentType: Playback.StationType.Live,
    contentName: stationName,
    section: 'player',
    onSuccess: () => {
      setIsMenuOpen?.(false);
    },
  });

  return isFollowing ?
      <MenuItem
        onAction={() => {
          unfollow();
        }}
      >
        Unfollow station
      </MenuItem>
    : <MenuItem
        onAction={() => {
          follow();
        }}
      >
        Follow station
      </MenuItem>;
});

export function Menu() {
  const state = playback.useState();
  const metadata = playback.useMetadata();
  const pageName = useGetPageName();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const fetcher = useFetcher();
  const markEpisodeAsPlayed = playback.useMarkEpisodeAsPlayed();
  const { adBreak } = playback.useAds();

  if (isNull(state.station) || isNull(metadata)) {
    return null;
  }

  const [userId, playlistId] = String(state.station.id).split('::');

  const { type } = state.station;

  const {
    id,
    artistId,
    artistName,
    albumId,
    albumName,
    completed,
    podcastId,
    podcastSlug,
    followable,
    transcriptionAvailable,
    title,
    subtitle,
  } = metadata.data;

  const albumUrl =
    artistId && albumId && albumName ?
      buildAlbumUrl({
        artist: { id: artistId, name: artistName ?? '' },
        album: { id: albumId, name: albumName },
      })
    : undefined;
  const artistUrl =
    artistId ?
      buildArtistUrl({ id: artistId, name: artistName ?? '' })
    : undefined;
  const stationUrl = id ? buildLiveUrl({ name: subtitle, id }) : undefined;
  const podcastUrl =
    podcastId && podcastSlug ?
      buildPodcastUrl({ podcastId, slug: podcastSlug })
    : undefined;
  const episodeUrl =
    id && podcastId && podcastSlug && subtitle ?
      buildPodcastEpisodeUrl({
        podcastId,
        podcastSlug,
        episodeId: id,
        episodeName: subtitle,
      })
    : undefined;
  const playlistUrl =
    playlistId && userId ?
      buildPlaylistUrl({
        name: title ?? '',
        userId,
        id: playlistId,
      })
    : undefined;

  return (
    <MenuTrigger isOpen={isMenuOpen} onOpenChange={setIsMenuOpen}>
      {/* <View isHidden={{ mobile: true, 'container-large': false }}> */}
      <Button
        color={{ light: 'gray', dark: 'white' }}
        kind="tertiary"
        size="icon"
      >
        <EllipsisHorizontal size={32} />
      </Button>
      {/* </View> */}
      <MenuContent>
        {(
          (type === Playback.StationType.PlaylistRadio ||
            type === Playback.StationType.Playlist) &&
          followable &&
          playlistId
        ) ?
          <FollowPlaylist
            playlistId={playlistId}
            playlistName={subtitle ?? title ?? ''}
            setIsMenuOpen={setIsMenuOpen}
            userId={userId}
          />
        : null}
        {type === Playback.StationType.Artist && artistId ?
          <FollowArtist
            artistId={artistId}
            artistName={artistName}
            setIsMenuOpen={setIsMenuOpen}
          />
        : null}
        {(
          (type === Playback.StationType.Live ||
            type === Playback.StationType.Scan) &&
          id
        ) ?
          <FollowLive
            setIsMenuOpen={setIsMenuOpen}
            stationId={id}
            stationName={title ?? ''}
          />
        : null}
        {(
          (type === Playback.StationType.Live ||
            type === Playback.StationType.Scan) &&
          stationUrl
        ) ?
          <MenuItem href={stationUrl}>Go to station</MenuItem>
        : null}
        {canGoToPlaylist && playlistUrl ?
          <MenuItem href={playlistUrl}>Go to playlist</MenuItem>
        : null}
        {canGoToArtist.has(type) && artistUrl ?
          <MenuItem href={artistUrl}>Go to artist</MenuItem>
        : null}
        {canGoToAlbum.has(type) && albumUrl ?
          <MenuItem data-test="go-to-album-option" href={albumUrl}>
            Go to album
          </MenuItem>
        : null}
        {type === Playback.StationType.Podcast && podcastId ?
          <FollowPodcast
            podcastId={podcastId}
            podcastName={subtitle ?? ''}
            setIsMenuOpen={setIsMenuOpen}
          />
        : null}
        {type === Playback.StationType.Podcast && podcastUrl ?
          <MenuItem href={podcastUrl}>Go to podcast</MenuItem>
        : null}
        {type === Playback.StationType.Podcast && episodeUrl ?
          <MenuItem href={episodeUrl}>View episode info</MenuItem>
        : null}
        {transcriptionAvailable && episodeUrl ?
          <>
            <MenuItem
              data-test="view-episode-transcript-option"
              href={`${episodeUrl}?viewTranscription=true`}
              onAction={() =>
                trackClick({
                  pageName,
                  action: 'miniplayer_overflow|transcription_option',
                })
              }
              routerOptions={{
                preventScrollReset: true,
              }}
            >
              View episode transcript
            </MenuItem>
          </>
        : null}
        {type === Playback.StationType.Podcast && !adBreak ?
          <MenuItem
            onAction={() => {
              const action =
                completed ? MARK_AS_UNPLAYED_ACTION : MARK_AS_PLAYED_ACTION;

              markEpisodeAsPlayed({ action, episodeId: id });

              fetcher.submit(
                {},
                {
                  action: $path(
                    '/api/v1/podcast/:podcastId/episode/:episodeId/:status',
                    {
                      podcastId,
                      episodeId: id,
                      status: action,
                    },
                  ),
                  method: 'POST',
                },
              );
            }}
          >
            {completed ? 'Mark as unplayed' : 'Mark as played'}
          </MenuItem>
        : null}
      </MenuContent>
    </MenuTrigger>
  );
}
