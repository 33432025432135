import { Box } from '@iheartradio/web.accomplice/box';
import { Button } from '@iheartradio/web.accomplice/button';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Browse, Home, Library } from '@iheartradio/web.accomplice/icons';
import { Link } from '@iheartradio/web.accomplice/link';
import { LogotypeBeta } from '@iheartradio/web.accomplice/logos';
import {
  Navigation,
  NavigationHeader,
  NavigationItem,
  NavigationList,
  NavigationLogo,
} from '@iheartradio/web.accomplice/navigation';
import { Separator } from '@iheartradio/web.accomplice/separator';
import { Spacer } from '@iheartradio/web.accomplice/spacer';
import { NavLink, useLocation, useMatches } from '@remix-run/react';
import { $path } from 'remix-routes';

import { NavAd } from '~app/ads/display/nav-ad';
import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { useAppsFlyer } from '~app/hooks/use-apps-flyer';
import type { RouteHandle } from '~app/types';
import { REG_GATE_TRIGGER_TYPES } from '~app/utilities/constants';

import { LoginSettingsNavItem } from './login-settings-item';

export function Nav() {
  const user = useUser();
  const { onAnalyticsRegGateOpen } = useRegGateEvent();
  const isMobile = useIsMobile();
  const appsFlyer = useAppsFlyer({ isMobile });
  const matches = useMatches() as { handle?: RouteHandle }[];

  const navProfile = matches.some(match => match.handle?.navProfile);

  const { pathname } = useLocation();

  return (
    <Box asChild data-test="nav-box" zIndex="$2">
      <Navigation
        data-has-button
        data-test="navigation-wrapper"
        key={`${user?.isAnonymous ? 'anon' : 'auth'}-nav`}
      >
        <Box asChild>
          <NavigationHeader navProfile={navProfile}>
            <NavLink to={$path('/')}>
              <LogotypeBeta
                aria-label="iHeart Header Logotype"
                media="mobile"
                size={24}
              />
            </NavLink>
            {isMobile ?
              <Button color="blue" onPress={() => appsFlyer?.generateLink()}>
                Listen on the app
              </Button>
            : null}
          </NavigationHeader>
        </Box>
        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="flex-start"
          width="100%"
        >
          <NavigationLogo>
            <Link href={$path('/')}>
              <LogotypeBeta
                aria-label="iHeart Navigation Logotype"
                media="desktop"
                size={24}
              />
            </Link>
          </NavigationLogo>
          <Flex direction="column" height="100%" justifyContent="space-between">
            <Flex
              direction="column"
              id="user-education"
              justifyContent="flex-start"
            >
              <NavigationList>
                <NavigationItem
                  asChild
                  isActive={pathname.startsWith('/recently-played/edit')}
                >
                  <NavLink data-test="home" end to={$path('/')}>
                    <Home />
                    Home
                  </NavLink>
                </NavigationItem>
                <NavigationItem
                  asChild
                  isActive={pathname.startsWith('/browse')}
                >
                  <NavLink
                    data-test="browse"
                    id="user-education-browse"
                    to="/browse/live"
                  >
                    <Browse />
                    Browse
                  </NavLink>
                </NavigationItem>
                <NavigationItem
                  asChild
                  isActive={pathname.startsWith('/library')}
                >
                  <NavLink
                    data-test="library"
                    id="user-education-library"
                    to="/library/live"
                  >
                    <Library />
                    Library
                  </NavLink>
                </NavigationItem>
                <Separator
                  display={{ mobile: 'none', large: 'inline-block' }}
                />
                <LoginSettingsNavItem
                  onClick={() =>
                    onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.LOG_IN)
                  }
                  user={user}
                />
              </NavigationList>
            </Flex>
            <Spacer bottom="$24" left="$8">
              <NavAd />
            </Spacer>
          </Flex>
        </Flex>
      </Navigation>
    </Box>
  );
}
