import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Flex } from '@iheartradio/web.accomplice/flex';
import type React from 'react';

import { Footer } from '~app/components/footer';
import { Nav } from '~app/components/navigation';
import { Player } from '~app/playback/player';

import { PageNavigationLoader } from '../page-navigation-loader';

export type LayoutProps = {
  children: React.ReactNode;
};

export function Layout({ children }: LayoutProps) {
  return (
    <>
      <Nav />
      <Player />
      <Flex
        asChild
        backgroundColor={lightDark(vars.color.gray100, vars.color.brandBlack)}
        data-test="main"
        flexDirection="column"
        id="main"
        minHeight="100dvh"
        paddingBottom={{ mobile: '12.4rem', large: '8.8rem' }}
        paddingLeft={{ mobile: 0, large: '31.6rem' }}
        paddingTop={{ mobile: '4.8rem', large: 0 }}
      >
        <main>
          <PageNavigationLoader />
          <Flex flexDirection="column" flexGrow={1} width="100%">
            <Box flexGrow={0} height="0px" id="scroll-target" width="100%" />
            {children}
          </Flex>
          <Box alignSelf="stretch">
            <Footer />
          </Box>
        </main>
      </Flex>
    </>
  );
}
