import { addToast } from '@iheartradio/web.accomplice/toast';
import type { PresetKeys, PresetsTypes } from '@iheartradio/web.api/amp';
import { isUndefined } from '@iheartradio/web.utilities';
import type { z } from 'zod';

import type { Presets } from './presets-storage';
import { PresetsStorage } from './presets-storage';

export const ContentTypes = {
  ARTIST: 'artist',
  COLLECTION: 'playlist',
  FAVORITES: 'station',
  LIVE: 'station',
  PODCAST: 'podcast',
};

type PresetIndex = (typeof PresetKeys)['_input'];

export const persistToStorage = (data: Presets, callback?: () => void) => {
  // Clear all items from memory storage
  Array.from({ length: 15 }, (_, i) => i).map(i =>
    PresetsStorage.remove(String(i) as PresetIndex),
  );

  // Save fresh new data to storage
  PresetsStorage.serialize(data, callback);
};

export function addPreset({
  id,
  title,
  type,
  position,
}: {
  id: string;
  title: string;
  type: z.infer<typeof PresetsTypes>;
  position?: PresetIndex;
}) {
  // TODO: Add cases for unauthenticated and if trying to add something that isn't playing
  const presets = PresetsStorage.deserialize();
  const keys = Object.keys(presets);
  const presetsLength = keys.length;

  // If presets is full -- error
  if (presetsLength === 15) {
    addToast({
      kind: 'error',
      text: `Your presets are full. Remove one to add this ${ContentTypes[type]}`,
    });
    return;
  }

  const itemAlreadyExists = Object.values(presets).find(preset => {
    return preset.id === id && preset.type === type;
  });

  // If specific item already exists within user's presets data -- error
  if (itemAlreadyExists) {
    addToast({
      kind: 'error',
      text: `${title} has already been added to your presets`,
    });
    return;
  }

  // If a position is not specified...
  if (!position) {
    // Find first available index - i.e. [0,1,2,4,7]: First available would be "3"
    const availableIndex = Array.from({ length: 15 }, (_, i) => i).find(
      (_, index) => !keys.includes(String(index) as PresetIndex),
    );

    // If a valid index was found, save to storage
    if (!isUndefined(availableIndex)) {
      persistToStorage(
        {
          ...presets,
          [String(availableIndex)]: { id, type, title },
        },
        () => addToast({ kind: 'success', text: `${title} added to presets` }),
      );
    }
  } else if (position && !keys.includes(position)) {
    // If specific position is provided and that position isn't filled in presets data, save to storage
    persistToStorage(
      {
        ...presets,
        [String(position)]: { id, type, title },
      },
      () => addToast({ kind: 'success', text: `${title} added to presets` }),
    );
  } else if (position && keys.includes(position)) {
    // If specific position is provided and that position is already filled in presets data -- error
    addToast({
      kind: 'error',
      text: `There was an issue adding ${title} to your presets. Please try again`,
    });
  }
}

export function deletePreset({
  title,
  position,
}: {
  title: string;
  position: PresetIndex;
}) {
  const presets = PresetsStorage.deserialize();
  const keys = Object.keys(presets);
  // If the position provided is occupied in the preset data...
  if (keys.includes(position)) {
    // Delete item from that position and save to storage
    delete presets[position];
    persistToStorage(presets, () =>
      addToast({ kind: 'success', text: `${title} removed from presets` }),
    );
  } else {
    addToast({
      kind: 'error',
      text: `There was an issue removing the preset. Please try again.`,
    });
  }
}

export function movePreset({
  currentPosition,
  newPosition,
}: {
  currentPosition: PresetIndex;
  newPosition: PresetIndex;
}) {
  const presets = PresetsStorage.deserialize();
  const keys = Object.keys(presets);

  // If the `newPosition` provided is not occupied, and is less than 14 (Maximum of 15 preset slots allowed)...
  if (
    !keys.includes(newPosition) &&
    presets[currentPosition] &&
    Number(newPosition) <= 14
  ) {
    // Assign preset to new index and delete the original
    presets[newPosition] = presets[currentPosition];
    delete presets[currentPosition];

    // Save to storage
    persistToStorage(presets);
  } else {
    addToast({
      kind: 'error',
      text: 'There was an issue reordering your presets. Please try again',
    });
  }
}
